import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider"
import CompareSliderHandle from "../../components/landing-page/CompareSliderHandle"
import { grey, lightBlack, mustard, skyBlue, white } from "../../constants/colors"
import { bigDesktop, desktop, largeDesktop, smallMobile, tablet } from "../../constants/screenSizes"
import { caflischFont, gillFont, minionFont } from "../../constants/fonts"
import { Icon } from "semantic-ui-react"
import ColorWrapper from "../../components/landing-page/ColorWrapper"
import Block from "../../components/landing-page/Block"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import LargeTitle from "../../components/landing-page/LargeTitle"
import Subheading from "../../components/landing-page/Subheading"
import BorderedButton from "../../components/button/BorderedButton"
import Description from "../../components/landing-page/Description"
import { Grid, GridImage, GridItem } from "../../components/landing-page/Grid"
import ScrollEcosystem from "../../components/landing-page/ScrollEcosystem"
import SvgIcon from "../../components/svg/SvgIcon"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import ReviewsIoCarousel, {
  ReviewsIoContainer,
} from "../../../components/home/reviews-io/ReviewsIoCarousel"
import SectionHeader from "../../components/landing-page/SectionHeader"
import BlogFeed, { BlogFeedContainer } from "../../components/landing-page/BlogFeed"
import LPRecommendationsBlock from "../../components/landing-page/new/components/reviews/LPRecommendationsBlock"
import DesktopHeadImage from "../../../svg/masthead-aboutus-desktop.svg"
import TabletHeadImage from "../../../svg/masthead-aboutus-tablet.svg"
import MobileHeadImage from "../../../svg/masthead-aboutus-mobile.svg"
import BurstDesktop from "../../../svg/block-burst-white-desktop.svg"
import BurstTablet from "../../../svg/block-burst-white-tablet.svg"
import BurstMobile from "../../../svg/block-burst-white-mobile.svg"
import ImageTeamJamie from "../../../images/image-team-jamie.jpg"
import ImageTeamAlex from "../../../images/image-team-alex.jpg"
import ImageTeamMatt from "../../../images/image-team-matt.jpg"
import ImageTeamKatie from "../../../images/image-team-katie.jpg"
import ImageTeamJames from "../../../images/image-team-james.jpg"
import ImageProblem from "../../../images/image-problem.jpg"
import ImageSolution from "../../../images/image-solution.jpg"
import ImageTeamJess from "../../../images/image-team-jess.jpg"
import IconQuality from "../../../svg/icon-quality.svg"
import IconCurated from "../../../svg/icon-curated.svg"
import IconInspiring from "../../../svg/icon-inspiring.svg"
import ImageShopping1 from "../../../images/image-shopping-1.jpg"
import ImageShopping3 from "../../../images/image-shopping-3.jpg"
import ImageShopping4 from "../../../images/image-shopping-4.jpg"
import ImageShopping5 from "../../../images/image-shopping-5.jpg"
import ImageShopping6 from "../../../images/image-shopping-6.jpg"
import ImageShopping7 from "../../../images/image-shopping-7.jpeg"
import IconDelivery from "../../../svg/icon-delivery-sticker.svg"
import IconPartnered from "../../../svg/icon-partnered-sticker.svg"
import IconStocked from "../../../svg/icon-stocked-sticker.svg"
import IconCorp from "../../../svg/icon-b-corp-sticker.svg"
import IconNeutral from "../../../svg/icon-carbon-neutral-sticker.svg"
import IconHelped from "../../../svg/icon-helped-sticker.svg"
import IconCharitable from "../../../svg/icon-charitable-contributions-sticker.svg"
import IconEconomic from "../../../svg/icon-economic-empowerment-sticker.svg"
import IconRepurposed from "../../../svg/icon-repurposed-sticker.svg"
import ImageEmploymentBefore from "../../../images/image-employment-before.jpg"
import ImageEmploymentAfter from "../../../images/image-employment-after.jpg"
import ImagePlasticsBefore from "../../../images/image-plastics-before.jpg"
import ImagePlasticsAfter from "../../../images/image-plastics-after.jpg"
import ImageRepurposeBefore from "../../../images/image-repurpose-before.jpg"
import ImageRepurposeAfter from "../../../images/image-repurpose-after.jpg"
import DesktopFundamentalImage from "../../../svg/illustration-ripple-block-end.svg"
import IconArrowDotted from "../../../svg/icon-arrow-dotted-down.svg"
import LPCorporateQuotesBlock from "../../components/landing-page/new/components/reviews/LPCorporateQuotesBlock"
import { ImpactType } from "@social-supermarket/social-supermarket-model"

const DifferenceSubheading = styled(Description)`
  font-family: ${minionFont};
  text-transform: none;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.83px;
  padding-bottom: 20px;

  @media (max-width: ${tablet}px) {
    padding-bottom: 10px;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 1.05px;
  }
  @media (max-width: ${smallMobile}px) {
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0.9px;
  }
`

const ShoppingTitle = styled(LargeTitle)`
  max-width: 525px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: ${bigDesktop}px) {
    max-width: unset;
    font-size: 28px;
    letter-spacing: 1.05px;
    line-height: 38px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
    letter-spacing: 0.9px;
    line-height: 34px;
  }
`

const ShoppingGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 60px;
  margin-top: 60px;

  @media (max-width: ${bigDesktop}px) {
    column-gap: 20px;
    row-gap: 20px;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`

const ShoppingItem = styled.div`
  display: flex;
  width: 100%;
  background-color: ${white};
  border-radius: 10px;

  @media (max-width: ${bigDesktop}px) {
    border-radius: 20px;
  }
`

const ShoppingItemImageContainer = styled.div`
  height: 278px;

  @media (max-width: ${bigDesktop}px) {
    height: 203px;
  }
`

const ShoppingItemImage = styled.img`
  border-radius: 10px 0 0 10px;
  object-fit: cover;
  height: 278px;
  width: auto;

  @media (max-width: ${bigDesktop}px) {
    height: 203px;
    border-radius: 20px 0 0 20px;
  }
`

const ShoppingItemText = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 40px 20px 30px 20px;
  width: 300px;

  @media (max-width: ${bigDesktop}px) {
    padding: 10px 5px 10px 10px;
    width: 200px;
  }
`

const ShoppingSubheading = styled.h5`
  font-family: ${caflischFont};
  font-weight: normal;
  line-height: 32px;
  font-size: 30px;
  letter-spacing: 1.33px;
  margin: 0;
  text-transform: none;

  @media (max-width: ${bigDesktop}px) {
    font-size: 28px;
    letter-spacing: 1.24px;
    line-height: 30px;
  }
`

const ShoppingTextTitle = styled(LargeTitle)`
  font-family: ${minionFont};
  font-size: 58px;
  font-weight: 600;
  letter-spacing: 1.8px;
  line-height: 58px;
  margin: 5px 0;
  color: ${mustard};

  @media (max-width: ${bigDesktop}px) {
    font-size: 40px;
    letter-spacing: 1.2px;
    line-height: 48px;
  }
`

const ShoppingDescription = styled(Description)`
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 24px;
  max-width: 250px;

  @media (max-width: ${bigDesktop}px) {
    font-size: 16px;
    letter-spacing: 0.71px;
    line-height: 22px;
  }
`

const ShoppingPart = styled(Part)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${bigDesktop}px) {
    width: ${({ width }) => width || "100%"};
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`

export const LinkIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`

const GoToLink = styled(GLink)<{ iconMargin?: string }>`
  margin-top: 35px;
  display: inline-flex;
  align-items: flex-start;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: ${mustard};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${mustard};
    text-decoration: none;
  }
  ${LinkIcon} {
    margin: ${({ iconMargin }) => iconMargin || 0};
    height: unset;
  }

  @media (max-width: ${desktop}px) {
    margin-top: 20px;
    font-size: 16px;
    line-height: 28px;
  }
`

const DifferenceGrid = styled(Grid)`
  margin-top: 28px;

  @media (max-width: ${desktop}px) {
    margin-top: 22px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    row-gap: 50px;
  }
  @media (max-width: ${smallMobile}px) {
    margin-top: 32px;
  }
`

const StyledGridItem = styled(GridItem)`
  @media (max-width: ${desktop}px) {
    flex-direction: row;
    max-width: 100%;
  }
  @media (max-width: ${smallMobile}px) {
    flex-direction: column;
  }
`

const StyledGridImage = styled(GridImage)`
  @media (max-width: ${desktop}px) {
    max-width: 213px;
    margin: 0 40px 0 0;
    object-fit: cover;
  }
  @media (max-width: ${smallMobile}px) {
    max-width: 300px;
    flex-direction: column;
    margin: 0 auto 20px;
  }
`

const Semibold = styled.span`
  font-weight: 600;
`

const HeadWrapper = styled(ColorWrapper)`
  background: ${skyBlue} url(${DesktopHeadImage}) no-repeat center center;
  background-size: auto;

  @media (max-width: ${tablet}px) {
    background-image: url(${TabletHeadImage});
    background-size: cover;
  }
  @media (max-width: ${smallMobile}px) {
    background-image: url(${MobileHeadImage});
  }
`

const HeadTitle = styled(LargeTitle)`
  font-family: ${minionFont};
  font-size: 60px;
  letter-spacing: 1.8px;
  line-height: 72px;
  text-align: center;
  max-width: 572px;
  width: 100%;

  @media (max-width: ${tablet}px) {
    padding: 140px 0;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 375px;
    padding: 192px 0;
    font-size: 40px;
    letter-spacing: 0.96px;
    line-height: 48px;
  }
`

const BurstWrapper = styled(ColorWrapper)`
  background-image: url(${BurstDesktop});
  background-color: ${skyBlue};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;

  @media (max-width: ${tablet}px) {
    background-image: url(${BurstTablet});
    background-size: cover;
  }
  @media (max-width: ${smallMobile}px) {
    background-image: url(${BurstMobile});
  }
`

const BurstPart = styled(Part)<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 460px;

  @media (max-width: ${largeDesktop}px) {
    width: 430px;
  }

  @media (max-width: ${bigDesktop}px) {
    width: 400px;
  }
  @media (max-width: ${desktop}px) {
    width: 330px;
  }
  @media (max-width: 735px) {
    width: 100%;
    max-width: unset;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`

const BurstInnerBlock = styled(InnerBlock)`
  @media (max-width: ${largeDesktop}px) {
    padding: 0 0 0 65px;
  }

  @media (max-width: ${bigDesktop}px) {
    padding: 0;
  }
`

const BurstText = styled.div`
  margin-bottom: 30px;
`

const BurstDescription = styled(Description)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.71px;
`
const TeamTitle = styled.div`
  width: 100%;
  margin: 25px 0 60px 0;
  @media (max-width: ${desktop}px) {
    margin: 32px 0 60px 0;
  }
  @media (max-width: ${smallMobile}px) {
    margin: 22px 0 40px 0;
  }
`

const TeamContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 40px;

  @media (max-width: ${desktop}px) {
    grid-template-columns: 1fr 1fr;
    gap: 55px;
  }
  @media (max-width: ${smallMobile}px) {
    grid-template-columns: 1fr;
    gap: 40px;
  }
`

const TeamItem = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamName = styled(Description)`
  font-family: ${minionFont};
  margin-top: 20px;
  font-size: 22px;

  @media (max-width: ${desktop}px) {
    font-size: 28px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
  }
`

const TeamPosition = styled(Description)`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1.7px;
`

const TeamLink = styled(GLink)`
  color: ${skyBlue};
  text-decoration: underline;
  &:hover {
    color: ${skyBlue};
  }
`

const TeamSignUp = styled.div`
  max-width: 765px;
  margin: 80px auto 0;
  text-align: center;

  @media (max-width: ${desktop}px) {
    margin: 60px auto 0;
  }
`

const Triangle = styled.div`
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 62px solid transparent;
  border-right: 62px solid transparent;
  border-bottom: 62px solid ${grey};
  position: absolute;
  top: -62px;
  right: 0;
  left: 0;
`

const ImpactBlock = styled(Block)`
  flex-direction: column;
  padding: 80px 40px;
`

const ImpactText = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 735px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;

  @media (max-width: ${desktop}px) {
    max-width: 631px;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 316px;
  }
`

const TitleImpact = styled(Title)`
  @media (max-width: ${desktop}px) {
    font-size: 28px;
    letter-spacing: 1.05px;
    line-height: 38px;
  }
`

const DescriptionImpact = styled(Description)`
  @media (max-width: ${desktop}px) {
    font-size: 16px;
    letter-spacing: 0.71px;
    line-height: 28px;
  }
`

const ColoredText = styled.span`
  color: ${skyBlue};
  font-weight: 600;
`

const ImpactIconsWrapper = styled.div`
  margin: 60px auto 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: ${desktop}px) {
    max-width: 640px;
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 330px;
    justify-content: space-between;
  }
`

const ImpactIcon = styled(SvgIcon)`
  svg {
    height: 140px;
  }

  @media (max-width: ${largeDesktop}px) {
    svg {
      height: 11.5vw;
    }
  }

  @media (max-width: ${bigDesktop}px) {
    svg {
      height: 11.4vw;
    }
  }

  @media (max-width: ${desktop}px) {
    svg {
      height: 120px;
    }
  }
`
const StyledInnerBlock = styled(InnerBlock)`
  margin-bottom: 100px;
  align-items: center;
  flex-direction: row-reverse;
  padding: 0 0 0 65px;
  @media (max-width: ${desktop}px) {
    padding: 0;
    margin-bottom: 80px;
  }
  &:nth-child(even) {
    flex-direction: row;
    margin-bottom: 0;
    padding: 0 65px 0 0;

    @media (max-width: ${desktop}px) {
      padding: 0;
    }
  }
`

const SolutionPart = styled(Part)`
  width: 44%;
  max-width: 442px;

  @media (max-width: ${desktop}px) {
    max-width: 100%;
    width: 100%;
  }
`

const SolutionTitle = styled(LargeTitle)`
  margin: 40px 0 25px 0;
  @media (max-width: ${desktop}px) {
    font-size: 28px;
    letter-spacing: 1.2px;
    line-height: 38px;
    margin: 20px 0 10px 0;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
    line-height: 34px;
  }
`

const StyledSubheading = styled(Subheading)`
  @media (max-width: ${desktop}px) {
    display: none;
  }
`

const MobileSubheading = styled(Subheading)`
  display: none;
  margin: 0 0 30px 0;
  @media (max-width: ${desktop}px) {
    display: inline-block;
  }
`

const ImageContainer = styled.div`
  max-width: 55%;
  @media (max-width: ${desktop}px) {
    max-width: 100%;
    width: 100%;
  }
`

const StyledImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const ExternalLink = styled(GLink).attrs({ as: "a" })`
  color: ${skyBlue};
  text-decoration: underline;
  &:hover,
  &:visited {
    color: ${skyBlue};
  }
`

const StyledBlock = styled(Block)`
  ${BlogFeedContainer} {
    border-top: none;
    border-bottom: none;
    padding-top: 0;
  }
`

const ReviewsBlock = styled(Block)`
  padding: 0 40px;

  ${ReviewsIoContainer} {
    padding: 0;
  }
`

const StyledGrid = styled(Grid)`
  column-gap: 80px;

  @media (max-width: ${largeDesktop}px) {
    column-gap: 30px;
  }

  @media (max-width: ${desktop}px) {
    grid-template-columns: 1fr 1fr;
    padding-left: 35px;
    row-gap: 22px;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
`

const GridItemContainer = styled(GridItem)`
  position: relative;
  border-radius: 10px;
  max-width: 337px;
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${grey};
  flex-direction: row;
  justify-self: right;
  align-items: center;

  @media (max-width: ${largeDesktop}px) {
    max-width: 300px;
  }

  @media (max-width: ${desktop}px) {
    justify-self: center;
  }

  @media (max-width: 650px) {
    max-width: 100%;
  }
`

const JourneyPart = styled(Part)`
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
`

const GridIcon = styled.img`
  position: absolute;
  transform: translateX(-35px);
  height: 108px;
  width: 108px;
`

const JourneyDescription = styled.div`
  width: 100%;
  align-self: start;
  margin: 10px 20px 10px 77px;
`

const NumberTitle = styled(LargeTitle)`
  display: block;
  font-family: ${minionFont};
  font-weight: 600;
  font-size: 60px;
  letter-spacing: 2.25px;
  line-height: 42px;
  padding: 0 0 5px 0;

  @media (max-width: ${desktop}px) {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 1.2px;
  }
`
const JouneySubheading = styled(Description)`
  font-family: ${caflischFont};
  text-transform: none;
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1.33px;
  padding-bottom: 12px;

  @media (max-width: ${desktop}px) {
    font-size: 28px;
    letter-spacing: 1.24px;
    line-height: 30px;
  }
`

const ColorfulText = styled.span<{ color?: string }>`
  color: ${({ color }) => color || `${skyBlue}`};
`

const FundamentalWrapper = styled(ColorWrapper)`
  background: url(${DesktopFundamentalImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  margin-top: -1px;

  @media (max-width: ${bigDesktop}px) {
    background-size: contain;
  }
`
const FundamentalInnerBlock = styled(InnerBlock)`
  background-color: ${grey};
  margin-bottom: 60px;
  border-radius: 20px 0 0 20px;
  padding: 0;
  position: relative;

  :last-child {
    margin-bottom: 20px;
  }

  @media (max-width: ${bigDesktop}px) {
    margin-bottom: 70px;
    border-radius: 20px;
  }

  @media only screen and (min-device-width: 768px) {
    :last-child {
      margin-bottom: 40px;
    }
  }
`

const FundamentalTextContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 772px;
  padding: 400px 0 0;

  @media (max-width: ${bigDesktop}px) {
    padding: 250px 0 0;
  }

  @media (max-width: ${desktop}px) {
    padding: 165px 0 0;
  }

  @media (max-width: ${tablet}px) {
    padding: 150px 0 0;
  }
`

const ArrowIcon = styled.img`
  width: 100%;
  max-width: 25px;
  height: auto;
  margin: 60px 0 0 0;
`

const MainTitle = styled(LargeTitle)`
  font-family: ${minionFont};
  font-size: 60px;
  letter-spacing: 1.8px;
  line-height: 72px;
  width: 100%;
`

const FundamentalImageContainer = styled.div`
  max-width: 610px;
  overflow: hidden;
  border-radius: 20px 0 0 20px;
  width: 100%;
  height: fit-content;
  position: relative;

  @media (max-width: ${largeDesktop}px) {
    max-width: 48vw;
  }

  @media (max-width: ${bigDesktop}px) {
    max-width: 100%;
    border-radius: 20px 20px 0 0;
  }
`

const FundamentalText = styled.span<{ isRightAligned?: boolean }>`
  position: absolute;
  top: 17px;
  ${({ isRightAligned }) => (isRightAligned ? `right: 17px;` : `left: 17px;`)};
  z-index: 1;
  font-family: ${gillFont};
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  color: ${white};

  @media (max-width: ${desktop}px) {
    top: 20px;
    ${({ isRightAligned }) => (isRightAligned ? `right: 20px;` : `left: 20px;`)};
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.88px;
  }
  @media (max-width: ${smallMobile}px) {
    display: none;
  }
`

const FundamentalBlock = styled(Block)`
  padding-bottom: 0;
`

const FundamentalPart = styled(Part)`
  max-width: 530px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${largeDesktop}px) {
    max-width: 39vw;
  }

  @media (max-width: ${bigDesktop}px) {
    max-width: 100%;
  }
`

const FundamentalIcon = styled.img`
  position: absolute;
  right: 0;
  transform: translate(16px, -20px);
  height: 106px;
  width: 106px;

  @media (max-width: ${largeDesktop}px) {
    transform: translate(30px, -20px);
  }

  @media (max-width: ${bigDesktop}px) {
    bottom: 0;
    transform: translate(16px, 45px);
  }
`

const DifferenceBlock = styled(Block)`
  padding-top: 90px;

  @media (max-width: ${desktop}px) {
    padding-top: 70px;
  }
`

const FundamentalLink = styled(GoToLink)`
  margin: 0 65px 60px 0;
  color: ${skyBlue};
  line-height: 30px;
  height: auto;
  margin-top: 0;

  @media (max-width: ${largeDesktop}px) {
    margin: 0 70px 20px 0;
  }

  @media (max-width: ${bigDesktop}px) {
    margin: 0 0 60px 42px;
  }

  @media (max-width: ${tablet}px) {
    margin: 0 0 60px 30px;
  }

  &:hover,
  &:visited {
    color: ${skyBlue};
  }
`

const DescriptionContainer = styled.div`
  margin: 55px 65px 0 0;

  @media (max-width: ${largeDesktop}px) {
    bottom: 0;
    margin: 20px 70px 20px 0;
  }

  @media (max-width: ${bigDesktop}px) {
    bottom: 0;
    margin: 40px 42px 20px;
  }

  @media (max-width: ${tablet}px) {
    bottom: 0;
    margin: 42px 30px 20px;
  }
`

const JourneyBlock = styled(Block)`
  margin-bottom: 20px;

  @media only screen and (min-device-width: 768px) {
    :last-child {
      margin-bottom: 40px;
    }
  }
`

const ItalicQuote = styled.i`
  color: ${lightBlack};
`

const StyledTitle = styled(Title)`
  @media (max-width: ${largeDesktop}px) {
    font-size: 28px;
    line-height: 32px;
  }
`

const JourneyTitle = styled(LargeTitle)`
  @media (max-width: ${tablet}px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (max-width: ${smallMobile}px) {
    font-size: 24px;
    line-height: 34px;
  }
`

const StyledDescription = styled(Description)`
  margin: 22px 0;
  @media (max-width: ${largeDesktop}px) {
    margin: 16px 0;
    line-height: 30px;
  }
`

const differenceItems = [
  {
    src: IconQuality,
    alt: "Quality",
    subheading: "Quality that tells a positive story",
    description: `Your purchase is contributing to good in the world. That doesn’t mean you sacrifice on function,
      taste or craftsmanship. Many of our brands have won the highest accolades for their products
      from Great Taste Awards to MBEs.`,
  },
  {
    src: IconCurated,
    alt: "Curated",
    subheading: "Curated with clarity",
    description: `Every item you see meets our stringent ‘no compromise’ criteria. We promise the highest quality, choice
      you’ll love and seamless service. It’s our aim that you should always be able to find a social impact
      swap for whatever you’re looking for.`,
  },
  {
    src: IconInspiring,
    alt: "Inspiring",
    subheading: "Inspiring change",
    description: `We’ve made shopping your values transparent and easy. Each product has icons that indicate its social
      or environmental impact, so that you can be clear on the impact your purchase has on the world around us.`,
  },
]

const journeyItems = [
  {
    src: IconDelivery,
    alt: "Delivery",
    subheading: "Delivered  over",
    number: `200,000`,
    description: `products as part of our hampers (and counting)`,
  },
  {
    src: IconPartnered,
    alt: "Partnered with over",
    subheading: "Curated with clarity",
    number: `200`,
    description: `brands onto our marketplace`,
  },
  {
    src: IconStocked,
    alt: "Stocked",
    subheading: "Stocked over",
    number: `2,000`,
    description: `products on our marketplace`,
  },
  {
    src: IconCorp,
    alt: "Corp",
    subheading: "Achieved",
    number: `B Corp`,
    description: `status in 2021`,
  },
  {
    src: IconNeutral,
    alt: "Neutral",
    subheading: "Become carbon",
    number: `Neutral`,
    description: `through key partnerships`,
  },
  {
    src: IconHelped,
    alt: "Helped",
    subheading: "Helped over",
    number: `300`,
    description: `Businesses to #BuySocial`,
  },
]

const burstItems = [
  {
    title: "For the individual",
    description: `Shop social — by switching to social enterprises. Find a social impact swap from
      everything from food to fashion, beauty to homewares.`,
    buttonUrl: "/",
    buttonLabel: "Marketplace",
  },
  {
    title: "For teams",
    description: `Whether you want to send some gifts to team members or clients, we’re here to
      help you #buysocial.`,
    buttonUrl: "/corporate",
    buttonLabel: "Corporate hub",
  },
]

const teamItems = [
  {
    img: ImageTeamJamie,
    name: "Jamie Palmer",
    position: "CEO & Co-Founder",
    description: (
      <>
        Loves his{" "}
        <TeamLink to="/product/ocean-bottle-reusable-water-bottle-500ml">
          Ocean Bottle water bottle
        </TeamLink>
        .
      </>
    ),
  },
  {
    img: ImageTeamAlex,
    name: "Alex Owen",
    position: "COO/CFO & CO-founder",
    description: (
      <>
        Suggests you check out{" "}
        <TeamLink to="/brand/redemption-roasters">Redemption Roasters</TeamLink> for a more
        purposeful cup of coffee.
      </>
    ),
  },
  {
    img: ImageTeamMatt,
    name: "Matt Dabell",
    position: "CTO & CO-Founder",
    description: (
      <>
        Never without his{" "}
        <TeamLink to="/product/elvis-kresse-card-holder">
          Elvis & Kresse firehose card holder
        </TeamLink>
        .
      </>
    ),
  },
  {
    img: ImageTeamKatie,
    name: "Katie Rose",
    position: "Head of Marketing & Brand",
    description: (
      <>
        Big fan of{" "}
        <TeamLink to="/brand/birdsong">Birdsong’s social impact clothes & accessories</TeamLink>{" "}
        that often feature important messages, while being made thoughtfully.
      </>
    ),
  },
  {
    img: ImageTeamJames,
    name: "James Manfield",
    position: "Head of Operations",
    description: (
      <>
        Recommends{" "}
        <TeamLink to="/product/rising-hope-cookbook">
          Luminary Bakery’s Rising Hope cookbook
        </TeamLink>{" "}
        for incredibly indulgent recipes while supporting an amazing cause.
      </>
    ),
  },
  {
    img: ImageTeamJess,
    name: "Jess Burns",
    position: "Account Manager — Corporate Sales",
    description: (
      <>
        Wants you to know about{" "}
        <TeamLink to="/product/sunny-rays-bamboo-socks">Leiho’s fun yellow socks</TeamLink> that
        donate a pair to someone experiencing homelessness.
      </>
    ),
  },
]

const solutionItems = [
  {
    title: "You want to shop with a clear conscience",
    subheading: "The Problem",
    description: (
      <>
        It's important to be able to contribute to the changes you want to see in the world. But how
        do you <ItalicQuote>'vote with your wallet'</ItalicQuote> without lengthy research, wading
        through potential greenwashing statements and accreditations? There are{" "}
        <ExternalLink
          href="https://www.socialenterprise.org.uk/policy-and-research-reports/the-hidden-revolution/"
          target="_blank"
        >
          100,000+ social enterprises in the UK
        </ExternalLink>{" "}
        but no one way to shop them all in one place…
      </>
    ),
    src: ImageProblem,
    alt: "The problem image",
  },
  {
    title: "Create a ripple of positive change every time you shop",
    subheading: "The Solution",
    description: (
      <>
        At Social Supermarket, we bring together businesses that are a force for good all under one
        virtual roof, verified by us and our partner{" "}
        <ExternalLink href="https://www.goodmarket.global/info/" target="_blank">
          Good Market.
        </ExternalLink>{" "}
        These social enterprises and purpose-led brands tackle human and environmental issues so you
        can make a real difference every time you shop.
      </>
    ),
    src: ImageSolution,
    alt: "The solution image",
  },
]

const shoppingItems = [
  {
    image: ImageShopping4,
    subheading: "Save",
    title: "8,751kg",
    description:
      "of food produce – the weight of a single-decker bus – and an increase of 47% over 2021",
  },
  {
    image: ImageShopping7,
    subheading: "Create",
    title: "73,290",
    description:
      "litres of clean drinking water via newly installed water points in developing countries",
  },
  {
    image: ImageShopping1,
    subheading: "Create",
    title: "1,599 hours",
    description: "of employment for underrepresented groups, an increase of 82% vs 2021",
  },
  {
    image: ImageShopping5,
    subheading: "Collect",
    title: "256,000",
    description: "plastic bottles from the Ocean",
  },
  {
    image: ImageShopping3,
    subheading: "Enable",
    title: "£20k",
    description: "of donations to good causes",
  },
  {
    image: ImageShopping6,
    subheading: "Donate",
    title: "1,245",
    description: "garments to people experiencing homelessness",
  },
]

const shoppingLinks = [
  {
    text: "See how other brands are currently making positive ripples of change",
    link: "/brands",
    linkLabel: "Discover our brand stories",
  },
  {
    text: "Are you interested in joining us?",
    link: "/who-do-we-work-with",
    linkLabel: "What makes a Social Supermarket brand?",
  },
]

const fundamentalItems = [
  {
    title: "Not everyone has the same employment opportunities",
    description: (
      <>
        Did you know that{" "}
        <ExternalLink
          href="https://www.autism.org.uk/what-we-do/news/new-data-on-the-autism-employment-gap"
          target="_blank"
        >
          78% of autisic adults are unemployed
        </ExternalLink>
        ? But when you buy from Harry Specters, 69p of every £1 spent directly goes to benefitting
        young autistic people and their families through employment, work experience and training.
      </>
    ),
    linkLabel: "Shop by brands that create economic empowerment for marginalised people.",
    link: "/impact/economic-empowerment",
    svg: IconEconomic,
    alt: "Icon economic",
    firstImage: ImageEmploymentBefore,
    firstImageAlt: "Employment before",
    secondImage: ImageEmploymentAfter,
    secondImageAlt: "Employment after",
  },
  {
    title: (
      <>
        Every year{" "}
        <ExternalLink href="https://plasticoceans.org/the-facts/" target="_blank">
          10 million tonnes of plastic
        </ExternalLink>{" "}
        end up in the ocean
      </>
    ),
    description: `But when you buy an Ocean Bottle they collect 1,000 plastic bottles
     that would otherwise be headed for the sea through a partnership with charity Plastic Bank.`,
    linkLabel: "Shop by brands that make charitable donations with every purchase",
    link: "/impact/charitable-contributions",
    svg: IconCharitable,
    alt: "Icon charitable",
    firstImage: ImagePlasticsBefore,
    firstImageAlt: "Plastics before",
    secondImage: ImagePlasticsAfter,
    secondImageAlt: "Plastics after",
  },
  {
    title: (
      <>
        <ExternalLink
          href="https://www.unep.org/resources/report/unep-food-waste-index-report-2021"
          target="_blank"
        >
          8–10% of global emissions
        </ExternalLink>{" "}
        come from food waste
      </>
    ),
    description: (
      <>
        <ExternalLink
          href="https://www.socialenterprise.org.uk/buysocial/ar-campaign/toast-ale-ar-campaign/"
          target="_blank"
        >
          A whopping 44% of the UK’s fresh bread goes to waste.
        </ExternalLink>{" "}
        But Toast Ale have created a circular model that repurposes one slice of surplus, fresh
        bread to brew beer — with a longer shelf life.
      </>
    ),
    linkLabel: "Shop by brands that repurpose would-be wasted ingredients or materials.",
    link: "/impact/repurposed",
    svg: IconRepurposed,
    alt: "Icon repurposed",
    firstImage: ImageRepurposeBefore,
    firstImageAlt: "Repurpose before",
    secondImage: ImageRepurposeAfter,
    secondImageAlt: "Repurpose after",
  },
]

const fundamentalDescriptionItems = [
  "Here, every business is a force for good. Every purchase you make creates a ripple of positive change for people and planet.",
  "Discover three of our brand partners and the issues they’re tackling head on.",
]

interface Props {
  impacts: ImpactType[]
}

const AboutLandingPage: FC<Props> = ({ impacts }): JSX.Element => (
  <Layout fullWidth fadeParameters={{ when: true }}>
    <SEO title="About" description="About" />
    <HeadWrapper>
      <Block>
        <HeadTitle margin="0 auto" padding="100px 0" color={white}>
          Create your own <Semibold>ripple of change</Semibold>
        </HeadTitle>
      </Block>
    </HeadWrapper>
    <ColorWrapper>
      <Block>
        {solutionItems.map(({ title, subheading, description, src, alt }) => (
          <StyledInnerBlock key={title}>
            <ImageContainer>
              <MobileSubheading isBordered borderColor={skyBlue} color={lightBlack}>
                {subheading}
              </MobileSubheading>
              <StyledImage src={src} alt={alt} />
            </ImageContainer>
            <SolutionPart>
              <StyledSubheading isBordered borderColor={skyBlue} color={lightBlack}>
                {subheading}
              </StyledSubheading>
              <SolutionTitle color={lightBlack}>{title}</SolutionTitle>
              <Description>{description}</Description>
            </SolutionPart>
          </StyledInnerBlock>
        ))}
      </Block>
    </ColorWrapper>
    <ScrollEcosystem />
    <FundamentalWrapper>
      <FundamentalBlock>
        <FundamentalTextContainer>
          <MainTitle color={lightBlack}>
            We exist to enable{" "}
            <ColorfulText color={skyBlue}>
              <Semibold>fundamental</Semibold>
            </ColorfulText>{" "}
            societal change
          </MainTitle>
          {fundamentalDescriptionItems.map(text => (
            <Description margin="30px 0 0 0" key={text}>
              {text}
            </Description>
          ))}
          <ArrowIcon src={IconArrowDotted} alt="Arrow icon" />
        </FundamentalTextContainer>
      </FundamentalBlock>
    </FundamentalWrapper>
    <ColorWrapper>
      <Block>
        {fundamentalItems.map(
          ({
            title,
            description,
            svg,
            alt,
            link,
            linkLabel,
            firstImage,
            firstImageAlt,
            secondImage,
            secondImageAlt,
          }) => (
            <FundamentalInnerBlock key={title}>
              <FundamentalImageContainer>
                <FundamentalText>Before</FundamentalText>
                <FundamentalText isRightAligned>After</FundamentalText>
                <ReactCompareSlider
                  handle={<CompareSliderHandle />}
                  itemOne={<ReactCompareSliderImage src={firstImage} alt={firstImageAlt} />}
                  itemTwo={<ReactCompareSliderImage src={secondImage} alt={secondImageAlt} />}
                />
              </FundamentalImageContainer>
              <FundamentalPart>
                <FundamentalIcon src={svg} alt={alt} />
                <DescriptionContainer>
                  <StyledTitle>{title}</StyledTitle>
                  <StyledDescription>{description}</StyledDescription>
                </DescriptionContainer>
                <FundamentalLink iconMargin="0 10px 0 0" to={link}>
                  <LinkIcon name="arrow right" color={skyBlue} />
                  <u>{linkLabel}</u>
                </FundamentalLink>
              </FundamentalPart>
            </FundamentalInnerBlock>
          )
        )}
      </Block>
    </ColorWrapper>
    <ColorWrapper background={grey}>
      <Triangle />

      <ImpactBlock>
        <ImpactText>
          <TitleImpact>
            Feeling inspired by these{" "}
            <ColoredText>
              <Semibold>social impact stories</Semibold>
            </ColoredText>
            ?
          </TitleImpact>
          <DescriptionImpact>
            Discover the products that align with your values and challenge the status quo with us.
            <br />
            Shop by:
          </DescriptionImpact>
        </ImpactText>
        <ImpactIconsWrapper>
          {impacts.map(({ iconSvg, id }) => (
            <ImpactIcon svg={iconSvg} key={id} />
          ))}
        </ImpactIconsWrapper>
      </ImpactBlock>
    </ColorWrapper>

    <JourneyBlock>
      <JourneyPart>
        <Subheading isBordered borderColor={mustard}>
          The Journey so far
        </Subheading>
        <JourneyTitle margin="37px 0 55px 0" color={lightBlack}>
          By being a part of our ecosystem,{" "}
          <ColorfulText color={mustard}>
            <Semibold>together</Semibold>
          </ColorfulText>{" "}
          we have
        </JourneyTitle>
      </JourneyPart>
      <StyledGrid>
        {journeyItems.map(({ src, alt, subheading, number, description }) => (
          <GridItemContainer key={subheading}>
            <GridIcon src={src} alt={alt} />
            <JourneyDescription>
              <JouneySubheading>{subheading}</JouneySubheading>
              <NumberTitle color={mustard}>{number}</NumberTitle>
              <Description lineHeight="24px">{description}</Description>
            </JourneyDescription>
          </GridItemContainer>
        ))}
      </StyledGrid>
    </JourneyBlock>

    <ColorWrapper background={grey}>
      <Triangle />
      <Block>
        <ShoppingTitle>
          By shopping with us in{" "}
          <ColorfulText color={mustard}>
            <Semibold>2022</Semibold>
          </ColorfulText>
          , you helped to
        </ShoppingTitle>
        <ShoppingGrid>
          {shoppingItems.map(({ image, subheading, title, description }) => (
            <ShoppingItem key={title}>
              <ShoppingItemImageContainer>
                <ShoppingItemImage src={image} alt="Shopping" />
              </ShoppingItemImageContainer>
              <ShoppingItemText>
                <ShoppingSubheading>{subheading}</ShoppingSubheading>
                <ShoppingTextTitle>{title}</ShoppingTextTitle>
                <ShoppingDescription>{description}</ShoppingDescription>
              </ShoppingItemText>
            </ShoppingItem>
          ))}
        </ShoppingGrid>
        <InnerBlock>
          <Part width="100%" padding="80px 0 0 0" wrapPadding="60px 0 0 0">
            <ShoppingTitle>
              <Semibold>And many more!</Semibold>
            </ShoppingTitle>
          </Part>
          {shoppingLinks.map(({ text, link, linkLabel }) => (
            <ShoppingPart key={linkLabel} width="40%" padding="60px 0 0 0" wrapPadding="40px 0 0 0">
              <Title>{text}</Title>
              <GoToLink iconMargin="0 10px 0 0" to={link}>
                <LinkIcon name="arrow right" color={mustard} />
                <u>{linkLabel}</u>
              </GoToLink>
            </ShoppingPart>
          ))}
        </InnerBlock>
      </Block>
    </ColorWrapper>

    <DifferenceBlock>
      <Part padding="0 0 0 65px">
        <Subheading isBordered borderColor={skyBlue}>
          The Social Supermarket Difference
        </Subheading>
      </Part>
      <DifferenceGrid>
        {differenceItems.map(({ src, alt, subheading, description }) => (
          <StyledGridItem key={subheading}>
            <StyledGridImage src={src} alt={alt} bottomMargin="45px" />
            <Description margin="20px 0 0 0" tabletMargin="10px 0 0 0">
              <DifferenceSubheading>{subheading}</DifferenceSubheading>
              {description}
            </Description>
          </StyledGridItem>
        ))}
      </DifferenceGrid>
    </DifferenceBlock>

    <BurstWrapper>
      <Block>
        <BurstInnerBlock>
          <Part width="100%" padding="0 0 25px 0" wrapPadding="0 0 20px 0">
            <Subheading isBordered borderColor={white} color={white}>
              How can i make change?
            </Subheading>
          </Part>
          {burstItems.map(({ title, description, buttonUrl, buttonLabel }) => (
            <BurstPart key={title}>
              <BurstText>
                <LargeTitle margin="0 0 10px 0" color={white}>
                  {title}
                </LargeTitle>
                <BurstDescription color={white}>{description}</BurstDescription>
              </BurstText>
              <BorderedButton to={buttonUrl} icon="arrow right">
                {buttonLabel}
              </BorderedButton>
            </BurstPart>
          ))}
        </BurstInnerBlock>
      </Block>
    </BurstWrapper>
    <LPCorporateQuotesBlock />

    <LPRecommendationsBlock title="Who has recommended us?" />
    {/*<ColorWrapper background={grey}>*/}
    {/*  <Block>*/}
    {/*    <InnerBlock>*/}
    {/*      <Subheading isBordered borderColor={skyBlue}>*/}
    {/*        Team*/}
    {/*      </Subheading>*/}
    {/*      <TeamTitle>*/}
    {/*        <Title>Who’s behind our team?</Title>*/}
    {/*      </TeamTitle>*/}
    {/*    </InnerBlock>*/}
    {/*    <TeamContainer>*/}
    {/*      {teamItems.map(({ img, name, position, description }) => (*/}
    {/*        <TeamItem key={name}>*/}
    {/*          <img src={img} alt={name} />*/}
    {/*          <TeamName>{name}</TeamName>*/}
    {/*          <TeamPosition>{position}</TeamPosition>*/}
    {/*          <Description>{description}</Description>*/}
    {/*        </TeamItem>*/}
    {/*      ))}*/}
    {/*    </TeamContainer>*/}
    {/*    /!*<TeamSignUp>*!/*/}
    {/*    /!*  <Title margin="0 0 40px 0">*!/*/}
    {/*    /!*    As we grow, we’re always looking for extraordinary talent. Sign up to get our job*!/*/}
    {/*    /!*    alerts.*!/*/}
    {/*    /!*  </Title>*!/*/}
    {/*    /!*  <BorderedButton*!/*/}
    {/*    /!*    to="https://www.linkedin.com/company/the-social-supermarket/jobs/?viewAsMember=true"*!/*/}
    {/*    /!*    color={skyBlue}*!/*/}
    {/*    /!*    secondaryColor={white}*!/*/}
    {/*    /!*  >*!/*/}
    {/*    /!*    Sign up*!/*/}
    {/*    /!*  </BorderedButton>*!/*/}
    {/*    /!*</TeamSignUp>*!/*/}
    {/*  </Block>*/}
    {/*</ColorWrapper>*/}

    <StyledBlock>
      <BlogFeed
        titleAlign="left"
        header={
          <SectionHeader
            subheading="Latest Blogs"
            title="What’s on our mind?"
            link="/blog"
            linkLabel="Go to our blog page "
          />
        }
        isDateVisible
      />
    </StyledBlock>
  </Layout>
)

export default AboutLandingPage
